import userReducer from "./features/login/reducer";
import kycReducer from "./features/kyc/reducer";
import chatReducer from "./features/chat/reducer";
import categoryReducer from "./features/categories/reducer";
import itemReducer from "./features/items/reducer";
import requesttabReducer from "./features/requesttab/reducer";
import reviewReducer from "./features/review/reducer";
import notificationReducer from "./features/notifications/reducer";
import bankinfoReducer from "./features/bankinfo/reducer";
import walletReducer from "./features/wallet/reducer";
import dashboardReducer from "./features/dashboard/reducer";
import employeeReducer from "./features/employee/reducer";
import establishmentconfigReducer from "./features/establishmentconfig/reducer";
import employeeroleReducer from "./features/employeerole/reducer";
import campaignReducer from "./features/campaign/reducer";
import couponReducer from "./features/coupon/reducer";
import orderReducer from "./features/orders/reducer";
import taxReportReducer from "./features/taxreport/reducer";
import taxInfoReducer from "./features/taxinfo/reducer";

const rootReducer = {
  user: userReducer,
  chat: chatReducer,
  notification: notificationReducer,
  dashboard: dashboardReducer,
  employee: employeeReducer,
  establishmentconfig: establishmentconfigReducer,
  wallet: walletReducer,
  employeerole: employeeroleReducer,
  requesttab: requesttabReducer,
  review: reviewReducer,
  bankinfo: bankinfoReducer,
  campaign: campaignReducer,
  kyc: kycReducer,
  item: itemReducer,
  coupon: couponReducer,
  order: orderReducer,
  category: categoryReducer,
  taxreport: taxReportReducer,
  taxinfo: taxInfoReducer,
};

export default rootReducer;
