/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { EmployeeProp } from "./types";

const initialStateValue: EmployeeProp = {
  employees: [],
  employeesLoading: true,
};

export const employeesSlice = createSlice({
  name: "employee",
  initialState: { value: initialStateValue },
  reducers: {
    setEmployees: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetEmployees: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setEmployees, resetEmployees } = employeesSlice.actions;

export default employeesSlice.reducer;
