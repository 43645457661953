/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { WalletProp } from "./types";

const initialStateValue: WalletProp = {
  wallet: {
    collected_cash: "",
    created_at: "",
    id: 0,
    pending_withdraw: "",
    total_earning: "",
    total_withdrawn: "",
    updated_at: "",
    vendor_id: 0,
  },
  walletLoading: true,
  withdrawal: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
  withdrawalLoading: true,
  withdrawParameter: { amount: 0, saveAccount: false },
  orderTransactionsLoading: true,
  orderTransactions: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
    data: [],
  },
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: { value: initialStateValue },
  reducers: {
    setWallet: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetWallet: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setWallet, resetWallet } = walletSlice.actions;

export default walletSlice.reducer;
