import { initializeApp } from "firebase/app";
import {
  getToken,
  getMessaging,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAPOWBDjp9n4CRjSJSYE7ODRhZReFf9cFE",
  authDomain: "touramp-9ed84.firebaseapp.com",
  projectId: "touramp-9ed84",
  storageBucket: "touramp-9ed84.appspot.com",
  messagingSenderId: "369502740128",
  appId: "1:369502740128:web:478d91e661de79e103b3c5",
  measurementId: "G-QHDNGG9VFS"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
// export const messaging = getMessaging(firebaseApp);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    // console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    // console.log(err);
    return null;
  }
})();

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js",
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};

// getFirebaseToken function generates the FCM token
export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return Promise.resolve(
          getToken(messagingResolve, {
            vapidKey: `BCwC6ohSjTN6aQtGd2xiVCPLj_xB8-zs3fQDI_kUf0Hfo7r-3b5iOEQfAI9wojPd349NNejTSLizxCpGV51FMK0`,
            serviceWorkerRegistration,
          })
        );
      });
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });

export const onMessageListener = async () => {
  const messagingResolve = await messaging;
  if (messagingResolve) {
    return new Promise((resolve) => {
      onMessage(messagingResolve, (payload) => {
        console.log("payload", payload);
        resolve(payload);
      });
    });
  } else {
    return null;
  }
};
