import { lazy } from "react";
import Redirect from "../Redirect";

const ControlPanel = lazy(() => import("../../layout"));

const Login = lazy(() => import("../../pages/Login"));
const Signup = lazy(() => import("../../pages/Signup"));
const KYC = lazy(() => import("../../pages/KYC"));
const ForgotPassword = lazy(() => import("../../pages/ForgotPassword"));

const pagesRoute = [
  {
    path: "/login",
    component: Login,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/signup",
    component: Signup,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/kyc",
    component: KYC,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
  {
    path: "/*",
    component: ControlPanel,
    meta: {
      redirectUrl: "/login",
      protectedRoute: true,
      role: "admin",
    },
  },
  {
    path: "*",
    component: Redirect,
    meta: {
      redirectUrl: "/",
      protectedRoute: false,
      role: "",
    },
  },
];

export default pagesRoute;
