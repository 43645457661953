import { createSlice } from "@reduxjs/toolkit";
import { KYCType } from "./types";

const initialStateValue: KYCType = {
  address: "",
  certificate: "",
  city_id: 0,
  country_id: 0,
  cover_photo: "",
  delivery_time: "",
  establishment_type: "",
  latitude: 0,
  logo: "",
  longitude: 0,
  name: "",
  phone: "",
  state_id: 0,
  tax: 0,
  zone_id: 0,
  back_document: "",
  document_type: "",
  email: "",
  front_document: "",
  ssn_nin: "",
  tax_number: "",
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState: { value: initialStateValue },
  reducers: {
    setKyc: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setKyc } = kycSlice.actions;
export default kycSlice.reducer;
