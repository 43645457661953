/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { OrdersPropType } from "./types";

const initialStateValue: OrdersPropType = {
  orders: {
    data: [],
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: "",
    previous_page_url: "",
  },
  ordersLoading: true,
  rider: null,
  riderLoading: true,
  rideDetail: null,
  createRider: null,
};

export const orderSlice = createSlice({
  name: "order",
  initialState: { value: initialStateValue },
  reducers: {
    setOrders: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetOrders: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setOrders, resetOrders } = orderSlice.actions;

export default orderSlice.reducer;
