/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { TaxInfoProp } from "./types";

const initialStateValue: TaxInfoProp = {
  taxInfo: {
    id: "",
    user_id: undefined,
    driver_id: undefined,
    establishment_id: "",
    tax_classification: "",
    company_legal_name: "",
    business_as: "",
    ein: "",
    street_address: "",
    city: "",
    state_id: 0,
    zip_code: "",
    created_at: "",
    updated_at: "",
  },
  taxInfoLoading: true,
};

export const taxInfoSlice = createSlice({
  name: "taxinfo",
  initialState: { value: initialStateValue },
  reducers: {
    setTaxInfo: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetTaxInfo: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setTaxInfo, resetTaxInfo } = taxInfoSlice.actions;

export default taxInfoSlice.reducer;
