import Loader from "../../assets/images/logo.png";

const RootLoading = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <img src={Loader} alt="" className="animate-ping duration-200" />
    </div>
  );
};

export default RootLoading;
