/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { DashboardProp } from "./types";
import dayjs from "dayjs";

const today = dayjs().add(1, "day").format("YYYY-MM-DD");

const initialStateValue: DashboardProp = {
  dashboard: null,
  dashboardLoading: true,
  date: {
    from: "",
    to: today,
  },
  dateFilter: "overall",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: { value: initialStateValue },
  reducers: {
    setDashboard: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetDashboard: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setDashboard, resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
