import { lazy } from "react";

const NotFound = lazy(() => import("../../components/ui/page/NotFound"));
const Dashboard = lazy(() => import("../../pages/Dashboard"));
const Wallet = lazy(() => import("../../pages/Wallet"));
const Categories = lazy(() => import("../../pages/Categories"));
const Items = lazy(() => import("../../pages/Items"));
const BankInfo = lazy(() => import("../../pages/BankInfo"));
const Coupon = lazy(() => import("../../pages/Coupon"));
const Orders = lazy(() => import("../../pages/Orders"));
const Review = lazy(() => import("../../pages/Review"));
const Profile = lazy(() => import("../../pages/Profile"));
const Chat = lazy(() => import("../../pages/Chat"));
const Help = lazy(() => import("../../pages/Help"));
const RequestTab = lazy(() => import("../../pages/RequestTab"));
const Campaign = lazy(() => import("../../pages/Campaign"));
const EmployeeRole = lazy(() => import("../../pages/EmployeeRole"));
const Employee = lazy(() => import("../../pages/Employee"));
const MyEstablishment = lazy(() => import("../../pages/MyEstablishment"));
const ExpenseReport = lazy(() => import("../../pages/ExpenseReport"));
const TaxReport = lazy(() => import("../../pages/TaxReport"));
const TaxInfo = lazy(() => import("../../pages/TaxInfo"));
const EstablishmentConfig = lazy(
  () => import("../../pages/EstablishmentConfig")
);

const controlPanel = [
  {
    path: "",
    component: Dashboard,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "reviews",
    component: Review,
  },
  {
    path: "tab-request/*",
    component: RequestTab,
  },
  {
    path: "wallet/*",
    component: Wallet,
  },
  {
    path: "tax-report/*",
    component: TaxReport,
  },
  {
    path: "tax-info/*",
    component: TaxInfo,
  },
  {
    path: "categories",
    component: Categories,
  },
  {
    path: "campaign/*",
    component: Campaign,
  },
  {
    path: "employee-role/*",
    component: EmployeeRole,
  },
  {
    path: "employees/*",
    component: Employee,
  },
  {
    path: "expense-report",
    component: ExpenseReport,
  },
  {
    path: "items/*",
    component: Items,
  },
  {
    path: "my-bank-info/*",
    component: BankInfo,
  },
  {
    path: "chat/*",
    component: Chat,
  },
  {
    path: "help",
    component: Help,
  },
  {
    path: ":page/*",
    component: Coupon,
  },
  {
    path: "orders/*",
    component: Orders,
  },
  {
    path: "my-establishment/*",
    component: MyEstablishment,
  },
  {
    path: "establishment-config/*",
    component: EstablishmentConfig,
  },

  {
    path: "*",
    component: NotFound,
  },
];

export default controlPanel;
