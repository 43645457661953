/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ReviewProp } from "./types";

const initialStateValue: ReviewProp = {
  reviews: [
    {
      id: 1,
      item_id: 3,
      establishment_id: 1,
      user_id: 1,
      comment:
        "Very delicious food. It was really awesome. Thanks to the restaurant.",
      attachment: "[]",
      rating: 5,
      order_id: 100001,
      item_campaign_id: null,
      status: 1,
      created_at: "2023-06-04T17:52:32.000+01:00",
      updated_at: "2021-08-21T14:55:50.000+01:00",
      user: {
        id: 1,
        firstname: "Emmanuel",
        lastname: "David",
        phone: 2348104212512,
        email: "emmaa@gmail.com",
        image: "",
        is_phone_verified: true,
        email_verified_at: "2023-05-29T23:25:44.000+01:00",
        interest: null,
        device_token: "Helldkjoihfjenjlkef",
        status: true,
        order_count: null,
        login_medium: null,
        social_id: "",
        zone_id: "",
        wallet_balance: "4125",
        ref_code: "",
        ref_by: "",
        temp_token: "",
        account_status: "ACTIVE",
        created_at: "2023-05-30T22:24:33.000+01:00",
        updated_at: "2023-05-30T22:24:33.000+01:00",
        delete_at: "",
      },
      item: {
        id: 3,
        name: "Burger Combo",
        description: "A combo Burger.",
        image: "2021-08-21-6120ad6b86273.png",
        category_id: 15,
        variations: [
          {
            name: "Size",
            type: "multi",
            min: "1",
            max: "1",
            required: "off",
            values: [
              {
                label: "Small",
                optionPrice: "400",
              },
              {
                label: "Large",
                optionPrice: "750",
              },
            ],
          },
        ],
        add_ons: [],
        attributes: [
          {
            id: 1,
            name: "Size",
            created_at: "2021-08-20T21:57:27.000+01:00",
            updated_at: "2021-08-20T21:57:27.000+01:00",
          },
        ],
        choice_options: [
          {
            name: "choice_1",
            title: "Size",
            options: ["Small", "Big"],
          },
        ],
        price: "80",
        tax: "0",
        tax_type: "percent",
        discount: "0",
        discount_type: "percent",
        available_time_starts: "00:01:00",
        available_time_ends: "23:59:00",
        status: true,
        establishment_id: 4,
        order_count: 1,
        avg_rating: 0,
        rating_count: 0,
        recommended: false,
        slug: null,
        created_at: "2023-06-02T13:06:44.000+01:00",
        updated_at: "2021-08-21T14:54:22.000+01:00",
        deleted_at: null,
        category: {
          id: 15,
          name: "Soft Drinks",
          image: "def.png",
          status: true,
          slug: "soft-drinks",
          created_at: "2023-05-26T16:28:23.000+01:00",
          updated_at: null,
        },
      },
    },
    {
      id: 2,
      item_id: 67,
      establishment_id: 1,
      user_id: 1,
      comment: "Nice",
      attachment: "[]",
      rating: 4,
      order_id: 100008,
      item_campaign_id: null,
      status: 1,
      created_at: "2023-06-04T17:52:35.000+01:00",
      updated_at: "2021-08-21T23:46:23.000+01:00",
      user: {
        id: 1,
        firstname: "Emmanuel",
        lastname: "David",
        phone: 2348104212512,
        email: "emmaa@gmail.com",
        image: "",
        is_phone_verified: true,
        email_verified_at: "2023-05-29T23:25:44.000+01:00",
        interest: null,
        device_token: "Helldkjoihfjenjlkef",
        status: true,
        order_count: null,
        login_medium: null,
        social_id: "",
        zone_id: "",
        wallet_balance: "4125",
        ref_code: "",
        ref_by: "",
        temp_token: "",
        account_status: "ACTIVE",
        created_at: "2023-05-30T22:24:33.000+01:00",
        updated_at: "2023-05-30T22:24:33.000+01:00",
        delete_at: "",
      },
      item: {
        id: 67,
        name: "Meat Pizza",
        description:
          "If you’re looking for a pie with a bit more heft, a meat pizza is a perfect and popular choice.",
        image: "2021-08-21-6120e6dadf410.png",
        category_id: 1,
        variations: [
          {
            name: "Size",
            type: "multi",
            min: "1",
            max: "1",
            required: "off",
            values: [
              {
                label: "Small",
                optionPrice: "400",
              },
              {
                label: "Large",
                optionPrice: "750",
              },
            ],
          },
        ],
        add_ons: [
          {
            id: 31,
            addon_name: "Pepsi",
            addon_price: "18.00",
            establishment_id: 2,
            status: true,
            created_at: "2021-08-21T18:29:34.000+01:00",
            updated_at: "2021-08-21T18:29:34.000+01:00",
          },
          {
            id: 34,
            addon_name: "Extra Meat",
            addon_price: "14.00",
            establishment_id: 2,
            status: true,
            created_at: "2021-08-21T18:32:24.000+01:00",
            updated_at: "2021-08-21T18:32:24.000+01:00",
          },
        ],
        attributes: [
          {
            id: 1,
            name: "Size",
            created_at: "2021-08-20T21:57:27.000+01:00",
            updated_at: "2021-08-20T21:57:27.000+01:00",
          },
        ],
        choice_options: [
          {
            name: "choice_1",
            title: "Size",
            options: ["Small", "Large"],
          },
        ],
        price: "400",
        tax: "0",
        tax_type: "percent",
        discount: "30",
        discount_type: "amount",
        available_time_starts: "02:00:00",
        available_time_ends: "22:00:00",
        status: true,
        establishment_id: 2,
        order_count: 1,
        avg_rating: 0,
        rating_count: 0,
        recommended: false,
        slug: null,
        created_at: "2023-06-02T13:11:13.000+01:00",
        updated_at: "2021-08-21T23:46:14.000+01:00",
        deleted_at: null,
        category: {
          id: 1,
          name: "Burger",
          image: "2021-08-20-611fbe0e334c5.png",
          status: true,
          slug: "burger",
          created_at: "2023-05-26T16:26:56.000+01:00",
          updated_at: null,
        },
      },
    },
    {
      id: 3,
      item_id: 108,
      establishment_id: 1,
      user_id: 1,
      comment: "nice",
      attachment: "[]",
      rating: 5,
      order_id: 100032,
      item_campaign_id: null,
      status: 1,
      created_at: "2023-06-04T17:52:38.000+01:00",
      updated_at: "2021-08-22T10:25:14.000+01:00",
      user: {
        id: 1,
        firstname: "Emmanuel",
        lastname: "David",
        phone: 2348104212512,
        email: "emmaa@gmail.com",
        image: "",
        is_phone_verified: true,
        email_verified_at: "2023-05-29T23:25:44.000+01:00",
        interest: null,
        device_token: "Helldkjoihfjenjlkef",
        status: true,
        order_count: null,
        login_medium: null,
        social_id: "",
        zone_id: "",
        wallet_balance: "4125",
        ref_code: "",
        ref_by: "",
        temp_token: "",
        account_status: "ACTIVE",
        created_at: "2023-05-30T22:24:33.000+01:00",
        updated_at: "2023-05-30T22:24:33.000+01:00",
        delete_at: "",
      },
      item: {
        id: 108,
        name: "Veg Momos",
        description:
          "Momos are popular street food in northern parts of India. These are also known as Dim Sum and are basically dumplings made from flour with a savory stuffing.",
        image: "2021-08-21-6121008b3c074.png",
        category_id: 7,
        variations: [],
        add_ons: [],
        attributes: [],
        choice_options: [],
        price: "320",
        tax: "0",
        tax_type: "percent",
        discount: "0",
        discount_type: "percent",
        available_time_starts: "03:31:00",
        available_time_ends: "14:31:00",
        status: true,
        establishment_id: 7,
        order_count: 1,
        avg_rating: 0,
        rating_count: 0,
        recommended: false,
        slug: null,
        created_at: "2021-08-21T20:32:59.000+01:00",
        updated_at: "2021-08-22T10:24:42.000+01:00",
        deleted_at: null,
        category: {
          id: 7,
          name: "Fast Food",
          image: "2021-08-20-611fbf30f1a68.png",
          status: true,
          slug: "fast-food",
          created_at: "2023-05-26T16:27:36.000+01:00",
          updated_at: null,
        },
      },
    },
  ],
  reviewsLoading: true,
};

export const reviewSlice = createSlice({
  name: "review",
  initialState: { value: initialStateValue },
  reducers: {
    setReview: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetReview: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setReview, resetReview } = reviewSlice.actions;

export default reviewSlice.reducer;
