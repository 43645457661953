/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { BankInfoPropType } from "./types";

const initialStateValue: BankInfoPropType = {
  bankInfo: [],
  bankInfoLoading: true,
};

export const bankInfoSlice = createSlice({
  name: "bankinfo",
  initialState: { value: initialStateValue },
  reducers: {
    setBankInfo: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetBankInfo: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setBankInfo, resetBankInfo } = bankInfoSlice.actions;

export default bankInfoSlice.reducer;
