/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { EstablishmentConfigPropType } from "./types";

const initialStateValue: EstablishmentConfigPropType = {
  schedule: [],
  scheduleLoading: true,
};

export const establishmentconfigSlice = createSlice({
  name: "establishmentconfig",
  initialState: { value: initialStateValue },
  reducers: {
    setEstablishmentconfig: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetEstablishmentconfig: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setEstablishmentconfig, resetEstablishmentconfig } =
  establishmentconfigSlice.actions;

export default establishmentconfigSlice.reducer;
