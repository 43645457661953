/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { TaxReportProp } from "./types";

const initialStateValue: TaxReportProp = {
  taxReport: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: "",
    last_page_url: "",
    next_page_url: undefined,
    previous_page_url: undefined,
    data: [],
  },
  taxReportLoading: true,
};

export const taxReportSlice = createSlice({
  name: "taxreport",
  initialState: { value: initialStateValue },
  reducers: {
    setTaxReport: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetTaxReport: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setTaxReport, resetTaxReport } = taxReportSlice.actions;

export default taxReportSlice.reducer;
