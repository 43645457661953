/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CategoryPropType } from "./types";

const initialStateValue: CategoryPropType = {
  category: [],
  categoryLoading: true,
};

export const categorySlice = createSlice({
  name: "category",
  initialState: { value: initialStateValue },
  reducers: {
    setCategory: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetCategory: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCategory, resetCategory } = categorySlice.actions;

export default categorySlice.reducer;
