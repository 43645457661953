import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RootLoading from "./components/RootLoading/RootLoading";
import Router from "./router/Router";

import { IRootState } from ".";

import Profile from "./utils/apiServices/profile";
import { login, logout } from "./features/login/reducer";
import { generateToken, zim } from "./utils/chat";
import { setChat } from "./features/chat/reducer";

function App() {
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer: any;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      dispatch(logout());
    }, 600000); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();
  const [rootLoading, setRootLoading] = useState(true);
  const user = useSelector((state: IRootState) => state.user.value);
  const { loggedIn } = user;
  // access token
  const accessToken =
    localStorage.getItem("accessToken") ||
    sessionStorage.getItem("accessToken") ||
    "";

  // Get Profile
  useEffect(() => {
    if (accessToken && !loggedIn) {
      Profile.getProfile()
        .then((res) => {
          if (res.success) {
            const { data } = res;

            const localToken = localStorage.getItem("accessToken");
            // Dispatch Login
            dispatch(
              login({
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone,
                email: data.email,
                country: data.country,
                email_verified_at: data.email_verified_at,
                password: data.password,
                bank_name: data.bank_name,
                branch: data.branch,
                holder_name: data.holder_name,
                account_no: data.account_no,
                establishment: data.establishment,
                image: data.image,
                status: data.status,
                device_token: data.device_token,
                vendor_id: data.vendor_id,
                created_at: data.created_at,
                updated_at: data.updated_at,
                phone_verified_at: data.phone_verified_at,
                account_status: data.account_status,
                temp_token: data.temp_token,
                country_id: data.country_id,
                zone_id: data.zone_id,
                state_id: data.state_id,
                loggedIn: true,
                remember_me: !!localToken,
                access_token: accessToken,
              })
            );
            const userId = data.establishment.establishment_id.toString();
            const userName = `${data.establishment?.name} (Vendor)` || "Vendor";
            const newUserDetails = { userName, userID: userId };
            zim
              .login(newUserDetails, generateToken(userId, 0))
              .then(function () {
                dispatch(
                  setChat({
                    isLogged: true,
                    isLoading: false,
                  })
                );
              });

            setRootLoading(false);
          } else {
            setRootLoading(false);
            // notify("error", `${res.message}`);
          }
        })
        .catch(() => {
          setRootLoading(false);
        });
    } else {
      setRootLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return <div className="">{rootLoading ? <RootLoading /> : <Router />}</div>;
}

export default App;
