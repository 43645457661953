/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { EmployeeRoleProp } from "./types";

const initialStateValue: EmployeeRoleProp = {
  employeerole: [],
  employeeRoleLoading: true,
};

export const employeesroleSlice = createSlice({
  name: "employeerole",
  initialState: { value: initialStateValue },
  reducers: {
    setEmployeeRole: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    resetEmployeeRole: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setEmployeeRole, resetEmployeeRole } =
  employeesroleSlice.actions;

export default employeesroleSlice.reducer;
